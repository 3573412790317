@import '../../../../scss/theme-bootstrap';

.product-sku-select {
  @include pie-clearfix;
  text-align: left;
  vertical-align: bottom;
  &__selectbox--no-carat {
    .selectBox-arrow {
      display: none;
    }
  }
  &__placeholder {
    @include selectBox;
    &:hover {
      cursor: normal;
    }
  }
  .selectBox {
    margin-bottom: 8px;
    text-align: left;
    padding: 2px 10px;
    padding-right: 30px; // arrow overlaps otherwise
    @include breakpoint($medium-up) {
      margin-bottom: 0;
    }
  }
  .product-qty-select__selectbox {
    min-width: 0;
  }
}

.selectBox-label {
  white-space: nowrap;
  display: block;
  width: 100%;
  overflow: hidden;
  min-width: 0.4em;
  @if $cr18 == true {
    font-size: 14px;
  }
  @if $cr18 == true {
    @include breakpoint($medium-up) {
      font-size: 18px;
      line-height: get-line-height(18px, 40px);
    }
  }

  .tabbed-menu__mobile & {
    text-align: center;
    font-family: $font--heading--tertiary;
    border-bottom: 2px solid $color-black;
    width: auto;
    margin: 0 15px;
  }
}

.selectbox-swatch {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: solid 1px $color-light-gray;
  margin: 0 19px 0 0;
  position: relative;
  top: 3px;
}
/* Endeca search results page */

.js-search_results__products {
  .product-sku-select {
    &::after {
      display: inline;
    }
  }
  .product-quickshop__sku-select__label {
    float: none;
  }
}
