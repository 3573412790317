// Core colors
$color-black: #000;
$color-white: #fff;
$color-light-gray: #eeede9;
$color-mid-gray: #898989;
$color-dark-gray: #595959;
$color-green: #36563d;
$color-light-green: #618b1e;
$color-ivory: #f5efe4;
$color-red: #f00;
$color-discount-red: #a80000;
// 2 new Core colors added
$color-light-red: #ff9999;
$color-darker-gray: $color-dark-gray;

// Creative Refresh 2018 variable.
$cr18: false !default;

// These colors are for _loyalty-marketing.scss
$color-blue: #0a9dc7;
$color-orange: #edba1e;
$color-drak-black: $color-black;
$color-dark-green: #006d53;
$color-light-white: #f4fdfc;
$color-sapling: #436711;
$color-sycamore: #305004;

// Abstract away the actual color names for common elements that may become
// entirely different colors. Don't overdo it.
$color-text: $color-black;
$color-link: $color-black;
$color-link-hover: $color-mid-gray;

// From the styleguide, potentially deprecated:
$color-silver: #f5f5f5; // keep this
$color-medium-gray: #c5c5c5;
$color-gray: $color-mid-gray;
$color-darkest-gray: #252525;
$color-light-gray: #e0e0e0;
$color-dark-blue: #2a6496;

// new for 2018 Creative Refresh
$color-cr18-black: #000;
$color-nav-2020-background: #fefefe;
$color-nav-2020-account: #f6efe5;

// Creative Refresh 2018 - changing color text variable.
@if $cr18 == true {
  $color-text: $color-cr18-black;
}

// found in gift cards, not sure what this is supposed to be: Dan Livingston
$color_celeste_approx: $color-white;

// we want rgba
$transparent--white: rgba(255, 255, 255, 0.75);
$transparent--black: rgba(0, 0, 0, 0.6);
$transparent--dark-black: rgba(0, 0, 0, 0.88);
$transparent--light-black: rgba(0, 0, 0, 0.3);

// Progress bar related colors
$color-active-panel: $color-green;
$color-inactive-panel: $color-dark-gray;
$color-progress-line: $color-darkest-gray;

// Sticky footer in checkout samples
$color-sticky-footer: #f3f3f3;
$color-sticky-footer-transparent: rgba(243, 243, 243, 0);
$color-silver-gray: #c1c1c1;

// VK login Colors
$color-facebook: #3c5a99;
$color-vkontakte: #4a76a8;

// OR Custom Kit Colors
$color-border: #c8c1c1;
$color-sticky-footer: #f4f4f6;
$color-sticky-footer-shadow: #c2c2c2;
$color-sticky-footer-border: #1c2328;
$color-lighter-white: #fbfbfb;
$color-christi-green: #70a912;
$color-deferred-orange: #f26622;
$color-dim-gray: #696969;
$color-overlay-gray: #f9f9f9;
$color-darker-green: #70be27;
$color-silver-green: #f8faf6;
$color-light-red: #fff2f3;

$color-suva-grey: #8c8c8c;
$color-medium-light-gray: #999;
$color-facebook-blue: rgba(59, 89, 152, 0.88);
$color-danube-blue: #5f7cbb;

$color-focus: #106bfe !default;
$color-navy-blue: #0075db;
$color-forest-green: #06891e;
$color-dark-red: #cc0000 !default;
$color-light-gray: #6d6d6d !default;
$color-lighter-gray: #e7e7e7 !default;
$color-lighter-grey: $color-lighter-gray !default;
$color-classic-matte-black: #1c1c1c !default;
$color-dark-grey: $color-dark-gray !default;

$color-light-gray-breadcrumbs: #5c6269;
$color--white-transparent-breadcrumbs: rgba(255, 255, 255, 0);
$color--white-breadcrumbs: rgba(255, 255, 255, 100);
$color-btn-grey: #333333;

// Password Strength
$color-success: #458745 !default;
$color-average: #dc6e10 !default;
$color-good: #dccc10 !default;
$check-mark: '\2713';
$cross-mark: '\2715';

$off-black: #222 !default;
$lux-alert-error-bg: #f9eeee;
$lux-alert-error-border: #e9bfbf;
$lux-panel-border: #d4d4d4;
$lux-cta-border: #212121;
$lux-alert-success-msg: #087443;
$lux-alert-success-bg: #f0f9f5;
$lux-alert-success-border: #bee3d2;
$lux-alert-info-msg: #e66100;
$warning-message-background: #fefbe6;
$warning-message-border: #feee95;
// Content Block
$color-moss-green: #36563d !default;
$color-dark-blue-icon: #3e474f !default;
$color-success-msg: #087443 !default;
$color-error-msg: #a80000 !default;
$color-alert-msg: #b54708 !default;
//TMO page
$color-tmo-thin-gray: #7f7f7f !default;
$color-tmo-thin-green: #007f5f !default;
$color-tmo-light-green: #f3fff8 !default;
$color-tmo-border-gray: #e5e5e5 !default;
$color-tmo-light-blue: #d08dde !default;
$color-tmo-green: #46ffc2 !default;
